html, body {
  height: 100%;
}

body > footer {
  flex-grow: 0;
  padding-bottom: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 100vh;
}

.footerMenu {
  width: 90%;
}

.footerMenu ul {
  flex-grow: 0;
}

.footerMenu li {
  margin-right: .8rem;
  display: inline;
}

#companyName {
  padding-bottom: 1rem;
  padding-left: 2rem;
}
/*# sourceMappingURL=composition.3b85c060.css.map */
